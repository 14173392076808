import axios from "axios";
import store from "../store/index";

store.commit("isRefreshing", false);
store.commit("refreshingCall", undefined);

// Base axios instance used in all files
// Change base url accordingly :p

store.commit("isRefreshing", false);
store.commit("refreshingCall", undefined);

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_URI,
    headers: {
        "Content-Type": "application/json",
    },
});

httpClient.defaults.headers.common[
    "Authorization"
] = `Bearer ${store.state.token}`;

let getNewToken = () => {
    if (
        store.state.isRefreshing != false &&
        store.state.refreshingCall != undefined
    ) {
        return store.state.refreshingCall;
    }
    store.commit("isRefreshing", true);

    const refreshingCall = new Promise((resolve, reject) => {
        httpClient
            .post("/refresh")
            .then((res) => {
                store.commit("SET_TOKEN", res.data.access_token);
                store.commit("isRefreshing", false);
                store.commit("refreshingCall", undefined);
                return resolve(res.data.access_token);
            })
            .catch((error) => {
                reject(error);
                store.commit("CLEAR_TOKEN");
                location.href = "login";

            });
    });
    store.commit("refreshingCall", refreshingCall);
    return refreshingCall;
};

httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error.response ? error.response.status : null;

        if (status === 401 && store.state.token != "") {
            return getNewToken().then((res) => {
                if (res) {
                    error.config.headers["Authorization"] = `Bearer ${res}`;
                    httpClient.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${res}`;
                    return httpClient.request(error.config);
                }
                store.commit("CLEAR_TOKEN");
                this.$router.push({ name: "Login" });
          
                return;
            });
        }

        return Promise.reject(error);
    }
);
export default httpClient;
