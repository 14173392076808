<template>
  <div id="loginView" class="sp_forms_views">
    <div class="flex-col img-login-panel">
      <video
        src="/Hembry preview.mp4"
        style="max-width: 100%; object-fit: cover"
        autoplay
        muted
        height="100%"
        loop
      ></video>
    </div>
    <div class="login-panel">
      <div class="flex-row-no-wrap logo-row">
        <img class="logo" src="../assets/logo.png" alt="" />
      </div>

      <!-- <form> -->
      <form class="auth-form" v-on:submit="handleRegister">
        <h2 style="margin-bottom: 0;">REGISTRERA</h2>
        

        <p v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </p>

        <label class="form-label" for="email">Email</label>
        <input
          id="email"
          type="email"
          class="login-text-input form-text-input"
          v-model="email"
          required
        />

        <label class="form-label" for="email">Lösenord</label>
        <input
          id="password"
          type="password"
          class="login-text-input form-text-input"
          v-model="password"
          required
        />

        <label class="form-label" for="confirmPassword"
          >Bekräfta lösenord</label
        >
        <input
          id="confirmPassword"
          type="password"
          class="login-text-input form-text-input"
          v-model="confirmedPassword"
          required
        />
        <div class="identityType sp_checkbox">
        <label class="form-label mt-0 mb-0">
          <input type="radio"  v-model="identityType" value="1"/> Privatperson 
        </label>
        <label class="form-label mt-0 mb-0">
          <input type="radio" v-model="identityType" value="2"/> Företag 
        </label>
        </div>
        <label class="form-label" for="identityNo"
          >Personnummer eller organisationsnummer</label
        >
        <input
          id="identityNo"
          type="text"
          placeholder="ÅÅÅÅMMDD-XXXX / ÅÅMMDD-XXXX"
          class="login-text-input form-text-input"
          v-model="identityNum"
          required
        />
        

        <div id="loginBtnRow" class="flex-row-no-wrap submit-btn-row">
          <button type="submit" id="loginBtn" class="submit-btn">
            <h4>Till kundportalen</h4>
            <img src="../assets/icons/arrow-right.svg" alt="" />
            <!-- Circle for login animation -->
            <transition name="enlarge"
              ><div id="circle" v-if="animationFlag"></div
            ></transition>
          </button>
          <div
            v-if="loading"
            style="
              align-self: center;
              margin-left: 1rem;
              border-radius: 50%;
              height: 20px;
              width: 24px;
              border: 3px solid #e7e7e7;
              border-top: 3px solid blue;
              animation: spinAnimation linear 2s infinite;
            "
          ></div>
  
        </div>
        
        <div class="sp_reg_bottom">
        <span>eller</span>
        <small><router-link to="Login">LOGGA IN</router-link></small>
        </div>
        
      </form>

      <!-- error section -->
      <div v-if="showError" class="error-container">
        <section>{{ errorMsg }}</section>
      </div>
      <div v-if="showSuccess" class="success-container">
        <section>Användaren har skapats. Välkommen!</section>
      </div>
    </div>
  </div>
</template>

<script>
import { register,login } from "@/api/auth.api.js";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      confirmedPassword: "",
      identityNum: "",
      animationFlag: false,
      loading: false,
      showSuccess: false,
      errorMsg: "",
      showError: false,
      errors: [],
      identityType: 1
    };
  },
  methods: {
    validatePersonNumber: function(){
      var re;
      if(this.identityType == 1){
      re = /^\d{8}-\d{4}$/;

      }else{
      re = /^\d{6}-\d{4}$/;
      }
      return re.test(this.identityNum);

    },
    handleRegister(e) {
      e.preventDefault();
      if (this.loading) return;
      this.loading = true;
      this.showError = false;
      this.errorMsg = "";

      if(!this.validatePersonNumber()){
        if(this.identityType == 1){
          this.errorMsg = "skriva åtta siffror följt av bindestreck följt av fyra siffror";
        }else{
          this.errorMsg = "skriva 6 siffror följt av bindestreck följt av fyra siffror";
        }
        this.showError = true;
        this.loading = false;
        return;
      }
      register(
        this.identityNum,
        this.email,
        this.password,
        this.confirmedPassword
      )
        .then(() => {
          this.loading = false;
          this.showError = false;
          this.showSuccess = true;
          setTimeout(() => {
           login(this.email, this.password).then(() => {
             this.$router.push({ name: "Home" })
           });
          },2000);
          
        })
        .catch((err) => {
          this.errorMsg = err;
          this.loading = false;
          this.showSuccess = false;
          this.showError = true;
        });
    },
  },
};
</script>


<style>

* {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
}
.sp_forms_views form > h4 {
    font-weight: 400;
    text-transform: lowercase;
    font-size: 15px;
    margin-top: 3px;
    margin-bottom: 27px;
}
.sp_forms_views input.form-text-input {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    -webkit-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 50%);
    box-shadow: 0 2px 3px -2px rgb(0 0 0 / 50%);
    border-color: #ccc;
    padding: 15px 12px;
    margin-top: 5px;
}
.sp_forms_views input.form-text-input:hover {
    -webkit-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 70%);
    -moz-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 70%);
    box-shadow: 0 2px 3px -2px rgb(0 0 0 / 70%);
    border-color: #aaa;
}
.sp_forms_views .form-label {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 11px;
}
.sp_forms_views button.submit-btn {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    padding: 15px 12px;
}
.auth-form h4 {
    line-height: 20px;
}
.sp_checkbox > label {
    background: #eee;
    padding: 15px 10px;
    text-align: center;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    margin-left: 3px;
    font-size: 12px;
    cursor: pointer!important;
    line-height: 18px;
}
.sp_checkbox > label > input {
    position: relative;
    top: 2px;
}
.sp_checkbox > label:hover,
.sp_checkbox > label input:checked + label {
    background: #aaa!important;
    color: #FFF!important;
}
.sp_reg_bottom {
    text-align: center;
}
.sp_reg_bottom > span {
    display: block;
    margin: 15px 0px 12px;
    color: #aaa;
    font-size: 14px;
}
.sp_reg_bottom > small a {
    text-decoration: none;
    text-transform: uppercase;
    color: #999;
    font-size: 13px;
}
.sp_reg_bottom > small a:hover {
    color: #0e3261;
    text-decoration: underline;
}


</style>


<style scoped>
[type=radio]{
  display: inline-block;
}
.mt-0{
  margin-top:0;
}
.mb-0{
  margin-bottom:0;
}
.identityType{
  display:grid;
  grid-template-columns: 1fr 1fr;
}
/* Change order of stacked columns on mobile */
 
@media (max-width: 767px) {
  .identityType{
    display: block;
  }
  .login-panel {
    order: 1;
  }
  
  .sp_forms_views .form-label,
  .sp_reg_bottom>small a {
    color: #666;
  }
  .sp_checkbox>label {
    display: inline-block;
    width: calc(50% - 3px);
    border: 1px solid #ccc;
  }
  .sp_checkbox > label:first-child {
      margin-left: 0;
    margin-right: 3px;
  }
  
  .img-login-panel {
    height: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
  }
}
 
 
/* Override grid ratio for register page */
@media (min-width: 768px) {
  #loginView {
    grid-template-columns: 2fr 3fr;
  }
}
</style>
