import httpClient from "./httpClient";

const cancelBooking = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post("/send-cancellation", data)
      .then((res) => {
        console.log(res);
        resolve();
      })
      .catch(() => reject());
  });
};

export { cancelBooking };
