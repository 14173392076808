<template>
  <div class="table-row">
    <h4 class="mobile-heading table-heading">Fakturanummer</h4>
    <h4 class="table-entry">{{ invoice.InvoiceNo }}</h4>
    <h4 class="mobile-heading table-heading">Fakturadatum</h4>
    <h4 class="table-entry">{{ invoiceDate }}</h4>
    <h4 class="mobile-heading table-heading">Förfallodatum</h4>
    <h4 class="table-entry">{{ invoiceDueDate }}</h4>
    <h4 class="mobile-heading table-heading">Betalt belopp</h4>
    <h4 class="table-entry" style="display:flex;" :class="dueNotPaid ? 'red-text' : ''">
      {{ parseInt(invoice.PaidAmount) }} SEK

      <div
        class="info-icon-container"
        v-if="parseInt(invoice.PaidAmount) == 0"
        @mouseenter="hoveringInfo = true"
        @mouseleave="hoveringInfo = false"
      >
        <span class="info-icon">&#8505;</span>
        <p class="info-popup" v-if="hoveringInfo">
          Den här fakturan saknar matchad inbetalning. Vänligen betala snarast.
          Vänligen notera att denautomatiska matchningen mellan dininbetalning
          och faktura kan ta upp till 2 arbetsdagar.
        </p>
      </div>
    </h4>
    <h4 class="mobile-heading table-heading">Att betala</h4>
    <h4 class="table-entry">{{ parseInt(totalAmount) }} SEK</h4>
    <h4 class="mobile-heading table-heading">Faktura</h4>
    <h4 class="table-entry">
      <a :href="url" target="_blank">Visa faktura</a>
    </h4>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient.js";

export default {
  name: "InvoiceLine",
  props: ["invoice"],
  mounted() {
    console.log(this.invoice);
    this.getInvoiceUrl();
  },
  data() {
    return {
      url: "",
      hoveringInfo: false
    };
  },
  methods: {
    getInvoiceUrl: function () {
      httpClient
        .get("/invoice-link?invoice_id=" + this.invoice.InvoiceId)
        .then((res) => {
          this.url = res.data;
          console.log(res);
        });
    },
  },
  computed: {
    totalAmount() {
      let total =
        this.invoice.TaxReductionType == "RUT"
          ? parseInt(this.invoice.TotalAmount) +
            parseInt(this.invoice.TaxReductionAmount)
          : this.invoice.TotalAmount;
      return total;
    },
    invoiceDate() {
      let invoiceDate = this.invoice.InvoiceDate.split("T");
      return invoiceDate[0];
    },
    invoiceDueDate() {
      let invoiceDate = this.invoice.DueDate.split("T");
      return invoiceDate[0];
    },
    dueNotPaid() {
      //let dueDate = new Date(this.invoice.DueDate);
      //let finalDate = new Date();
      //finalDate.setDate(dueDate.getDate() + 2);
     if (this.invoice.PaidAmount == 0) return true;
      return false;
    },
  },
};
</script>

<style>
.table-entry {
  margin: 0;
  font-weight: 400;
  font-size: 1em;
}
.table-loan-status {
  color: #35c779;
}

.table-profile {
  color: #6053b7;
  border: 1px solid transparent;
  transition: ease-out 0.2s;
}

.table-loan {
  color: #d96e27;
  border: 1px solid transparent;
  transition: ease-out 0.4s;
}

.table-profile,
.table-loan {
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  justify-self: start;
}

.red-text {
  color: red;
}
</style>
