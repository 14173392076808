<template>
  <div id="loginView" class="sp_forms_views">
    <div class="login-panel">
      <div class="flex-row-no-wrap logo-row">
        <img class="logo" src="../assets/logo.png" alt="" />
      </div>

      <!-- <form> -->
      <form id="loginForm" class="auth-form" v-on:submit="handleLogin">
        <h2>LOGGA IN</h2>
        <label class="form-label" for="email">Email</label>
        <input
          id="email"
          type="email"
          class="form-text-input"
          v-model="email"
          required
        />
        <label class="form-label" for="password">Lösenord</label>
        <input
          id="password"
          type="password"
          class="form-text-input"
          v-model="password"
          required
        />

        <div id="loginBtnRow" class="flex-row-no-wrap submit-btn-row">
          <button
            id="loginBtn"
            class="submit-btn"
            type="submit"
            :disabled="loading"
          >
            <h4>Till kundportalen</h4>
            <img src="../assets/icons/arrow-right.svg" alt="" />
            <!-- Circle for login animation -->
            <transition name="enlarge"
              ><div v-if="animationFlag" id="circle"></div
            ></transition>
          </button>
          <div
            v-if="loading"
            style="
              align-self: center;
              margin-left: 1rem;
              border-radius: 50%;
              height: 20px;
              width: 24px;
              border: 3px solid #e7e7e7;
              border-top: 3px solid blue;
              animation: spinAnimation linear 2s infinite;
            "
          ></div>
        </div>

        <div class="form_bottm">
        <small>
          <router-link to="Register">REGISTRERA</router-link></small
        > <span class="sp_separator">/</span>
        <small><router-link to="forgot-password"
            >Återställ lösenord</router-link
          ></small
        >
        </div>
        
      </form>

      <!-- error section -->
      <div v-if="showError" class="error-container">
        <section>E-postadressen eller lösenordet existerar inte.</section>
      </div>
    </div>
    <div class="flex-col login-img-panel">
      <video
        src="/Hembry preview.mp4"
        style="max-width: 100%; object-fit: cover"
        autoplay
        muted
        height="100%"
        loop
      ></video>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/auth.api.js";

export default {
  name: "Login",
  data() {
    return {
      animationFlag: false,
      loading: false,
      email: "",
      password: "",
      showError: false,
    };
  },
  methods: {
    handleLogin(e) {
      e.preventDefault();
      if (this.loading == true) return;

      this.loading = true;

      login(this.email, this.password)
        .then(() => {
          setTimeout(() => {
            this.animationFlag = true;
            setTimeout(() => this.$router.push({ name: "Home" }), 2000);
          }, 2000);
        })
        .catch(() => {
          this.loading = false;
          this.showError = true;
        });
    },
  },
};
</script>

<style>
/* Big screens */
@media (min-width: 768px) {
  #loginView {
    grid-template-columns: 3fr 2fr;
    overflow: hidden;
  }

  .login-panel {
    padding: 3rem;
    padding-left: 5rem;
  }
}

/* Mobile screens */


* {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
}
.sp_forms_views form > h2 {
    margin-bottom: 27px;
}
.sp_forms_views input.form-text-input {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    -webkit-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 50%);
    box-shadow: 0 2px 3px -2px rgb(0 0 0 / 50%);
    border-color: #ccc;
    padding: 15px 12px;
    margin-top: 5px;
}
.sp_forms_views input.form-text-input:hover {
    -webkit-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 70%);
    -moz-box-shadow: 0 2px 3px -2px rgb(0 0 0 / 70%);
    box-shadow: 0 2px 3px -2px rgb(0 0 0 / 70%);
    border-color: #aaa;
}
.sp_forms_views .form-label {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 11px;
}
.sp_forms_views button.submit-btn {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    padding: 15px 12px;
}
#loginForm h4 {
    line-height: 20px;
}
.form_bottm {
    text-align: center;
    display: block;
    margin-top: 20px;
}
.form_bottm > small {
    display: inline-block;
}
.form_bottm > small > a {
    text-decoration: none;
    text-transform: uppercase;
    color: #999;
    font-size: 13px;
}
.sp_separator {
    color: #999;
    margin: 0 7px;
}
.form_bottm > small > a:hover {
    color: #0e3261;
    text-decoration: underline;
}

#loginView {
  height: 100vh;
  display: grid;
}

.login-panel {
  display: grid;
  overflow: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 3fr 0.5fr;
  background-color: #ffffff;
}
.login-panel label {
  margin-bottom: 0.4rem;
}

.login-img-panel {
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(253, 239, 255, 1) 0%,
    rgba(219, 233, 242, 1) 100%
  );
}

.auth-form {
  margin: auto;
  width: 300px;
}

.form-text-input {
  padding: 0.75rem 0;
  text-indent: 0.5rem;
  width: 100%;
  /* max-width: 50%; */
  border-radius: 5px;
  /* border: 1px solid #8c6b9c; */
  border: 1px solid #ababab;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.form-text-input::placeholder {
  opacity: 0.69;
}

.form-label {
  font-size: small;
  /* color: #8c6b9c; */
  color: #ababab;
}

.submit-btn {
  width: 100%;
  background-color: rgba(14, 50, 97, 1);
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 25px;
  position: relative;
  display: flex !important;
  flex-flow: row nowrap;
  /* align-items: center; */
  /* justify-content: center; */
  cursor: pointer;
}

.submit-btn:focus {
  outline: none;
}

.submit-btn-row {
  margin-bottom: 0.5rem;
}

.submit-btn > img {
  height: 20px;
}

.submit-btn > h4 {
  margin: 0;
  margin: auto;
}

.logo-row {
  align-self: start;
}

#circle {
  /* background-color: #ffffff; */
  background-color: #f1f6fc;
  border-radius: 50%;
  position: fixed;
  height: 0px;
  width: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.enlarge-enter-active {
  animation: enlarge-anim 2.5s;
}

@keyframes enlarge-anim {
  0% {
    height: 0px;
    width: 0px;
  }

  100% {
    width: 300vw;
    height: 300vw;
  }
}

@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logo {
  height: 50px;
}

.logo-title {
  margin: 0;
  align-self: center;
}

.auth-form h2 {
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 2em;
  font-weight: 900;
}

.auth-form h4 {
  margin-top: 0;
}

.auth-form label,
.auth-form input,
.auth-form button {
  display: block;
}

.auth-form small {
  /* color: #ac89b4; */
  color: #ababab;
  margin-top: 0.5rem;
}

.auth-form small > a {
  /* text-decoration: underline;
  
  cursor: pointer; */
  /* color: #ac89b4; */
  color: #ababab;
}

.error-container,
.success-container {
  margin: auto;
}

.error-container section {
  background-color: #cc0000;
  color: #ffffff;
  padding: 1rem;
  border-radius: 4px;
  opacity: 0.69;
}

.success-container section {
  background-color: #4bb543;
  color: #ffffff;
  padding: 1rem;
  border-radius: 4px;
  opacity: 0.69;
}
@media (max-width: 767px) {
  #loginView {
    grid-template-columns: 1fr;
    grid-template-rows: 100vh;
    overflow-x: hidden;
  }

  .login-img-panel {
    height: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
  }

  .login-panel {
    background: inherit;
    padding: 1rem;
    z-index:3;
  }
  .auth-form {
    background: rgba(255,255,255,0.8);
    padding: 30px 20px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
     -webkit-box-shadow: 0 8px 6px -6px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0 8px 6px -6px rgb(0 0 0 / 30%);
    box-shadow: 0 8px 6px -6px rgb(0 0 0 / 30%);
    width: 95%;
  }
  #loginForm>.form-label,
  #loginForm .form_bottm>small>a {
    color: #666;
  }
}
</style>
