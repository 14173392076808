<template>
  <div class="modal" @click.stop="closeRate">
    <div class="modal-content">
      <div class="flex-row-no-wrap modal-header">
        <img src="@/assets/logo-inverted.png" alt="" />
      </div>
      <h4>
        Bokning: <span class="modal-highlight">{{ getDate }}</span> med
        {{ bookingData.EmployeeName }}
      </h4>
      <StartRating
        class="modal-rating"
        v-model="rating"
        :increment="0.5"
        :star-size="25"
        :show-rating="false"
      />
      <label>Skriv feedback sa att vi kan förbättra oss (frivilligt)</label>
      <textarea
        class="modal-textarea"
        cols="30"
        rows="10"
        v-model="review"
      >
      </textarea>
      <button class="request-btn" @click="submitReview" v-if="!loading">
        SKICKA
      </button>
      <div class="loading-spinner" v-else></div>
    </div>
  </div>
</template>

<script>
import StartRating from "vue-star-rating";
import { getWeekDay } from "@/js/helpers.js";
import { submitReview } from "@/api/review.api.js";

export default {
  name: "BookingRateModal",
  components: { StartRating },
  props: { bookingData: Object },
  data() {
    return {
      rating: 0,
      review: "",
      loading: false,
    };
  },
  methods: {
    closeRate(e) {
      if (e.target.className == "modal") {
        this.$emit("close-rate");
      }
    },
    submitReview() {
      if (!this.rating) return;
      this.loading = true;
      let weekday = getWeekDay(this.bookingData.StartDate.split(" ")[0]);
      let date = this.bookingData.StartDate.split(" ")[0];
      let time = this.bookingData.StartTime + "-" + this.bookingData.EndTime;
      let data = {
        Dag: weekday,
        Datum: date,
        Tid: time,
        Personal: this.bookingData.EmployeeName,
        Betyg: this.rating,
        Tjanst: "Hemstädning",
        Feedback: this.review,
        booking: this.bookingData.id,
      };
      submitReview(data)
        .then(() => {
          this.$emit("refresh-bookings");

          this.$emit("close-rate",true);
          
        })
        .catch(() => (this.loading = false));
    },
  },
  computed: {
    getDate() {
      let weekday = getWeekDay(this.bookingData.StartDate.split(" ")[0]);
      let date = this.bookingData.StartDate.split(" ")[0];
      let time = this.bookingData.StartTime.split(":");
      let endTime = this.bookingData.EndTime;
      return `${weekday} ${date} ${time[0]}:${time[1]} - ${endTime}`;
    },
  },
};
</script>

<style>
</style>