import httpClient from "./httpClient";
import store from "../store";

// Auth functions used in login and register pages
const forgot_password = (password, code) => {
    return new Promise((resolve, reject) => {
        httpClient
            .post("/forgot-password", {
                password: password,
                code
            })
            .then(() => {
                resolve("Password Changed");
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    reject("Invalid Reset Code");
                } else if (err.response.status == 500) {
                    reject("Identity number doesnt exist or the user is invalid");
                }
            });
    });
};

const code_reset = (email) => {
    return new Promise((resolve, reject) => {
        httpClient
            .post("/code-reset", {
                email
            })
            .then(() => {
                resolve("Code Sent");
            })
            .catch(() => {
                reject("Coudln't send code");

            });
    });
}
const register = (identityNo, email, password, confirmPassword) => {
    return new Promise((resolve, reject) => {
        httpClient
            .post("/check-user", {
                identityNo,
                email,
            })
            .then((response) => {
                httpClient
                    .post("/register", {
                        email: email,
                        name: response.data.name,
                        password: password,
                        password_confirmation: confirmPassword,
                        customerId: response.data.customer_id.toString(),

                    })
                    .then(() => {
                        resolve("Account created");
                    })
                    .catch(() => {
                        reject("Identity number and email dont match!");
                    });
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    reject("Email already exist");
                } else if (err.response.status == 401) {
                    reject("Email not consistend with identity number");
                } else if (err.response.status == 500) {
                    //Erro 500 identity num doesnt exist at tangella
                    reject("Identity number or email doesnt exist");
                }
            });
    });
};

const login = (email, password) => {
    return new Promise((resolve, reject) => {
        httpClient
            .post("login", { email, password })
            .then((response) => {
                store.commit("SET_TOKEN", response.data.access_token);
                resolve(response);
            })
            .catch(() => reject("Invalid Email or password!"));
    });
};

export { register, login, forgot_password, code_reset };

