<template>
  <div class="view">
    <NavBar />

    <RecentInvoices />
  </div>
</template>

<script>
import NavBar from "../components/dashboard-components/NavBar";
import RecentInvoices from "../components/dashboard-components/RecentInvoices.vue";

export default {
  name: "Invoices",
  components: {
    NavBar,
    RecentInvoices,
  },
};
</script>

<style>
</style>