import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Bookings from "../views/Bookings.vue";
import Invoices from "../views/Invoices.vue";
import Settings from "../views/Settings.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/bokingar",
        name: "bokingar",
        component: Bookings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword
    },
    {
        path: "/reset-password/:code",
        name: "ResetPassword",
        component: ResetPassword
    },
    {
        path: "/fakturor",
        name: "fakturor",
        component: Invoices,
        meta: {
            requiresAuth: true,
        },
    },

];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.state.token) {
        next({ name: "Login" });
    } else next();
});

export default router;
