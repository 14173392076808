<template>
  <div id="loginView">
    <div class="flex-col img-login-panel">
      <video
        src="/Hembry preview.mp4"
        style="max-width: 100%; object-fit: cover"
        autoplay
        muted
        height="100%"
        loop
      ></video>
    </div>
    <div class="login-panel">
      <div class="flex-row-no-wrap logo-row">
        <img class="logo" src="../assets/logo.png" alt="" />
      </div>

      <!-- <form> -->
      <form class="auth-form" v-on:submit="handleRegister">
        <h2>Återställ lösenord</h2>
        <label class="form-label" for="password">Nytt lösenord</label>
        <input
          id="password"
          type="password"
          class="login-text-input form-text-input"
          v-model="password"
          required
        />

        <div id="loginBtnRow" class="flex-row-no-wrap submit-btn-row">
          <button type="submit" id="loginBtn" class="submit-btn">
            <h4>Återställ lösenord</h4>
            <img src="../assets/icons/arrow-right.svg" alt="" />
            <!-- Circle for login animation -->
            <transition name="enlarge"
              ><div id="circle" v-if="animationFlag"></div
            ></transition>
          </button>
          <div
            v-if="loading"
            style="
              align-self: center;
              margin-left: 1rem;
              border-radius: 50%;
              height: 20px;
              width: 24px;
              border: 3px solid #e7e7e7;
              border-top: 3px solid blue;
              animation: spinAnimation linear 2s infinite;
            "
          ></div>
        </div>

        <small><router-link to="/Login">LOGGA IN</router-link></small>
      </form>

      <!-- error section -->
      <div v-if="showError" class="error-container">
        <section>{{ errorMsg }}</section>
      </div>
      <div v-if="showSuccess" class="success-container">
        <section>Lösenordsåterställning lyckades, nu kan du logga in</section>
      </div>
    </div>
  </div>
</template>

<script>
import { forgot_password, login } from "@/api/auth.api.js";

export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      animationFlag: false,
      loading: false,
      showSuccess: false,
      errorMsg: "",
      showError: false,
    };
  },
  methods: {
    handleRegister(e) {
      e.preventDefault();
      if (this.loading) return;
      this.loading = true;
      forgot_password(this.password, this.$route.params.code)
        .then((res) => {
          this.loading = false;
          this.showError = false;
          this.showSuccess = true;

          setTimeout(() => {
            login(res.data.email, this.password).then(() => {
              this.$router.push({ name: "Home" });
            });
          }, 2000);
        })
        .catch((err) => {
          this.errorMsg = err;
          this.loading = false;
          this.showSuccess = false;
          this.showError = true;
        });
    },
  },
};
</script>


<style>
</style>


<style scoped>
/* Change order of stacked columns on mobile */

.auth-form {
  width: 300px;
}

@media (max-width: 767px) {
  .login-panel {
    order: 1;
  }

  .img-login-panel {
    height: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
  }
  .img-login-panel::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}

/* Override grid ratio for register page */
@media (min-width: 768px) {
  #loginView {
    grid-template-columns: 2fr 3fr;
  }
}
</style>