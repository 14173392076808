<template>
  <div class="view">
    <NavBar />
    <form method="post" v-on:submit="changePassword" action="">
      <h3>Ändra lösenord</h3>

      <p v-if="message">{{ message }}</p>
      <ul v-if="errors">
        <li
          v-for="(error, index) in errors"
          :key="index"
          v-html="error.join('<br>')"
        ></li>
      </ul>
      <label>Nuvarande lösenord</label>
      <input type="password" class="form-text-input" v-model="cur_password" />
      <label>Nytt lösenord</label>
      <input type="password" class="form-text-input" v-model="password" />
      <button id="loginBtn" type="submit" class="submit-btn">
        <h4>Ändra lösenord</h4>
        <img src="../assets/icons/arrow-right.svg" alt="" /><!---->
      </button>
    </form>

    <form method="post" v-on:submit="changeSettings" action="">
      <h3>Notisinställningar</h3>
      <p v-if="notification_message">{{ notification_message }}</p>
      <label style="margin-bottom: 20px"
        ><input type="checkbox" name="optin" value="1" v-model="email_optin" />
        Skicka mailpåminnelser</label
      >
      <button id="loginBtn" type="submit" class="submit-btn">
        <h4>Ändra Notisinställningar</h4>
        <img src="../assets/icons/arrow-right.svg" alt="" /><!---->
      </button>
    </form>
  </div>
</template>
<style scoped>
label {
  width: 100%;
  display: block;
  margin-bottom: 5px;
}
form {
  width: 400px;
  max-width: 100%;
}
</style>
<script>
import httpClient from "@/api/httpClient";
import NavBar from "../components/dashboard-components/NavBar";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      cur_password: "",
      password: "",
      errors: {},
      message: "",
      user: {},
      email_optin: 1,
      notification_message: "",
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser: function () {
      httpClient.get("/user").then((res) => {
        this.user = res.data;
        this.email_optin = this.user.email_optin;
      });
    },
    changeSettings: function (event) {
      if (event) event.preventDefault();
      httpClient
        .post("/settings", {
          email_optin: this.email_optin,
        })
        .then((res) => {
          this.notification_message = res.data.message;
        });
    },
    changePassword: function (event) {
      if (event) event.preventDefault();
      httpClient
        .post("/reset-with-token", {
          current_password: this.cur_password,
          password: this.password,
        })
        .then((res) => {
          this.message = res.data.message;
          this.errors = [];
        })
        .catch((res) => {
          this.errors = res.response.data.errors;
          this.message = res.response.data.message;
        });
    },
  },
};
</script>