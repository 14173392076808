<template>
  <div class="view">
    <NavBar />

    <UpcomingBookings2 />
    
    <PastBookings />

    <RecentInvoices />

    <ExtraRequests />

    <Deviation />
  </div>
</template>

<script>
import NavBar from "../components/dashboard-components/NavBar";
import UpcomingBookings2 from "../components/bookings-components/UpcomingBookings.vue";
import PastBookings from "../components/bookings-components/PastBookings.vue";

import RecentInvoices from "../components/dashboard-components/RecentInvoices.vue";
import ExtraRequests from "../components/dashboard-components/ExtraRequests";
import Deviation from "../components/dashboard-components/Deviation";

export default {
  name: "Dashboard",
  components: {
    NavBar,
    PastBookings,
    UpcomingBookings2,
    RecentInvoices,
    ExtraRequests,
    Deviation,
  },
};
</script>

<style>
@media (min-width: 768px) {
  .view {
    padding: 4.5rem;
    padding-top: 2rem;
  }
}

@media (max-width: 767px) {
  .view {
    padding: 1rem;
  }
}

.view {
  background-color: #f1f6fc;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: start;
  row-gap: 2.5rem;
  /* min-height: 100vh; */
  min-height: 100%;
}
</style>