<template>
  <div class="flex-col">
    <div class="flex-row-no-wrap section-title-row">
      <h4 class="section-title">Registrera avvikelse</h4>
      <div
        class="info-icon-container"
        @mouseenter="hoveringInfo = true"
        @mouseleave="hoveringInfo = false"
      >
        <span class="info-icon">&#8505;</span>
        <p class="info-popup" v-if="hoveringInfo">
          Beskriv något du är missnöjd med eller tycker att vi ska förbättra. Vi
          är tacksamma för feedback som hjälper oss att förbättra din
          upplevelse. Vänligen skriv vilken adress ärendet gäller ifall vi utför
          tjänster på flera adresser.
        </p>
      </div>
    </div>

    <form v-on:submit="sendDeviation" method="post" class="dashboard-form">
      <textarea
        class="text-area"
        rows="6"
        id="message"
        v-model="message"
      />
      <input
        type="file"
        style="display: none"
        id="file"
        name="file"
        @change="handleFileChange"
      />

      <div class="d-none" style="text-align: center">
        <img
          src="../../assets/icons/paperclip.svg"
          v-on:click="openFile"
          alt=""
        />
      </div>
      <div class="file-info" v-if="pickedFile">
        <small>{{ pickedFile }}</small> <button @click="clearFile">klar</button>
      </div>

      <button class="request-btn" :disabled="progress == true" type="submit">
        Skicka meddelande
      </button>
    </form>
    <p v-if="response.type">{{ response.message }}</p>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient";

export default {
  name: "Deviation",
  methods: {
    openFile() {
      document.querySelector("#file").click();
    },
    handleFileChange() {
      let fileName = document.getElementById("file").value;
      if (fileName) {
        fileName = fileName.split("\\");
        this.pickedFile = fileName[fileName.length - 1];
      }
    },
    clearFile() {
      document.getElementById("file").value = null;
      this.pickedFile = "";
    },
    sendDeviation(event) {
      if (event) event.preventDefault();
      this.progress = true;

      var formData = new FormData();
      var imagefile = document.querySelector("#file");
      formData.append("attachment", imagefile.files[0]);
      formData.append("message", this.message);

      httpClient
        .post("deviation", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.type == "success") {
            this.response = res.data;
          }
          this.progress = false;
          this.message = "";

          setTimeout(() => {
            this.response = [];
          }, 3000);
        });
    },
  },
  data() {
    return {
      progress: false,
      message: "",
      response: [],
      pickedFile: "",
      hoveringInfo: false,
    };
  },
};
</script>

<style >
.file-info {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  align-items: center;
}

.file-info button {
  margin-left: 1rem;
  border-radius: 5px;
  background-color: transparent;
  border: none;
}
</style>

<style scoped>
img {
  width: 20px;
  margin: 0.5rem auto;

  cursor: pointer;
}
</style>