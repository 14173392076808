const getWeekDay = (date) => {
  var weekdays = [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
  ];
  var dayIndex = new Date(date).getDay();
  var day = weekdays[dayIndex];

  return day;
};

const compareDates = (a, b) => {
  let d1 = new Date(a.StartDate);
  let d2 = new Date(b.StartDate);
  if (d1 > d2) return -1;
  if (d1 < d2) return 1;
  return 0;
};

export { getWeekDay, compareDates };
