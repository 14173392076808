<template>
  <div>
    <div class="flex-row-no-wrap section-title-row">
      <h4 class="section-title">Fakturor</h4>
      <router-link
        class="show-all"
        to="fakturor"
        v-if="this.$router.currentRoute.name != 'fakturor'"
        >Visa alla</router-link
      >
    </div>

    <!-- Nafs el bookings table bel zabt
    If you need to change structure change here
    W mtnsash t8yr el invoice line -->

    <!-- Byzhr zay ma howa fel homepage wel page bta3t el invoices
    So if you need different data for homepage and invoices page
    Check current route in mounted and call right method <3 -->

    <div class="table-container sp_fack_dash" v-if="invoicesLoaded">
      <div class="table-row">
        <h4 class="table-heading">Fakturanummer</h4>
        <h4 class="table-heading">Fakturadatum</h4>

        <h4 class="table-heading">Förfallodatum</h4>
        <h4 class="table-heading">
          Betalt belopp
        </h4>

        <h4 class="table-heading">Att betala</h4>
        <h4 class="table-heading">Faktura</h4>
      </div>

      <InvoiceLine
        v-for="invoice in invoices"
        :invoice="invoice"
        :key="invoice.InvoiceId"
      />
    </div>

    <div class="loading-section" v-else>
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import InvoiceLine from "./InvoiceLine";
import { invoices } from "@/api/invoices.api.js";

export default {
  name: "Invoices",
  components: {
    InvoiceLine,
  },
  mounted() {
    if (this.$router.currentRoute.name == "fakturor") {
      //Data for invoices page
      invoices()
        .then((res) => {
          this.invoicesLoaded = true;
          this.invoices = res;
        })
        .catch();
    } else {
      //Data for homepage , limit to 5, fetch recent only, 3eesh 7yatak yabo el honod
      invoices(3)
        .then((res) => {
          this.invoicesLoaded = true;
          this.invoices = res;
        })
        .catch();
    }
  },
  data() {
    return {
      invoicesLoaded: false,
      invoices: [],
      hoveringInfo: false,
    };
  },
};
</script>

<style>
.table-container {
  background-color: #ffffff;
  padding-top: 0.5rem;
  font-weight: 600;
  border-radius: 10px;
}

.table-row {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  padding: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e7e7e7;
  transition: ease-out 0.4s;
}

.table-row:not(:first-of-type):hover {
  background: linear-gradient(
    90deg,
    rgba(237, 251, 254, 1) 0%,
    rgba(241, 239, 252, 1) 100%
  );
}

.table-row:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-row:not(:first-of-type):hover > .table-loan {
  border: 1px solid #d96e27;
}

.table-row:not(:first-of-type):hover > .table-profile {
  border: 1px solid #6053b7;
}
.sp_fack_dash > div:first-child > h4:nth-child(5),
.sp_fack_dash > div:not(:first-child) > h4:nth-child(9),
.sp_fack_dash > div:not(:first-child) > h4:nth-child(10) {
  display: none;
}

.table-heading {
  display: flex;
  flex-direction: row;
  margin: 0;
  color: rgb(128, 128, 128, 0.69);
  /* opacity: 0.69; */
  font-weight: 500;
}

.mobile-heading {
  display: none;
}

@media (max-width: 767px) {
  .table-row:first-of-type {
    color: royalblue !important;
    display: none;
  }
  .table-row {
    grid-template-columns: 1fr 1fr;
  }
  .mobile-heading {
    display: flex;
  }
}
</style>
