<template>
  <div class="flex-col">
    <div class="flex-row-no-wrap section-title-row">
      <h4 class="section-title">Begär extratjänster</h4>
      <div
        class="info-icon-container"
        @mouseenter="hoveringInfo = true"
        @mouseleave="hoveringInfo = false"
      >
        <span class="info-icon">&#8505;</span>
        <p class="info-popup" v-if="hoveringInfo">
          Beskriv detaljerat vad du behöver hjälp med. Vi återkopplar inom kort
          med en offert för arbetet
        </p>
      </div>
    </div>

    <form
      v-on:submit="sendCustomerMessage"
      method="POST"
      class="dashboard-form"
    >
      <textarea
        class="text-area"
        v-model="message"
        rows="6"
        required
      />

      <button class="request-btn" :disabled="progress == true" type="submit">
        Skicka meddelande
      </button>
    </form>
    <p v-if="response.type">{{ response.message }}</p>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient";

export default {
  name: "ExtraRequests",
  data() {
    return {
      progress: false,
      message: "",
      response: [],
      hoveringInfo: false,
    };
  },
  methods: {
    sendCustomerMessage: function (event) {
      if (event) event.preventDefault();
      this.progress = true;
      httpClient.post("send-message", { message: this.message }).then((res) => {
        if (res.data.type == "success") {
          this.response = res.data;
        }
        this.progress = false;
        this.message = "";

        setTimeout(() => {
          this.response = [];
        }, 3000);
      });
    },
  },
};
</script>

<style>
button,
textarea {
  font-family: "Roboto", sans-serif;
}
.text-area {
  padding: 2rem 0;
  border: none;
  columns: 40;
  width: 100%;
  resize: vertical;
  text-indent: 2rem;
  font-size: 1em;
  outline: none;
  border: 2px solid transparent;
  margin-bottom: 1rem;
  border-radius: 10px;
  transition: 0.3s;
}

.text-area:focus {
  border: 2px solid #91c4ff;
  /* box-shadow: 0 0 5px rgba(81, 203, 238, 1); */
}

.request-btn {
  margin: auto;
  width: 150px;
  border-radius: 5px;
  padding: 0.75rem 0;
  border: none;
  background-color: rgba(14, 50, 97, 1);
  color: #ffffff;
  cursor: pointer;
  border-radius: 50em;
}
.request-btn:disabled {
  opacity: 0.5;
  position: relative;
  width: 170px;
  padding-left: 15px;
}
.request-btn:disabled:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 20px;
  width: 15px;
  height: 15px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.dashboard-form {
  display: flex;
  flex-direction: column;
}

.d-block {
  margin: auto;
}

.info-icon-container {
  display: inline-block;
  position: relative;
  margin-left: 1rem;
  width: 15px;
  height: 15px;
  padding: 2px;
  align-self: center;
  background-color: #ececec;
  border-radius: 50%;
  opacity: 1 !important;
}

.info-icon {
  /* display: block; */
  position: absolute;
  cursor: default;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1 !important;
  font-size: 12px;
  color: black;
}

.info-popup {
  position: absolute;
  left: -300%;
  background: rgb(0, 0, 0, 1);
  opacity: 1 !important;
  color: #fff !important;
  padding: 1rem;
  border-radius: 10px;
  width: 33vw;
  z-index: 45;
}
</style>