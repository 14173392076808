<template>
  <div>
    <div class="flex-row-no-wrap section-title-row">
      <h4 class="section-title">Tidigare bokningar</h4>
    </div>

    <!-- 
      Change bookings table structure here 
      Efteker t8yrha fel bookings line bardo :P
      -->

    <div class="table-container" v-if="bookingsLoaded">
      <div class="table-row">
        <h4 class="table-heading">Veckodag</h4>
        <h4 class="table-heading">Datum</h4>
        <h4 class="table-heading">Tid</h4>
        <h4 class="table-heading">Städare</h4>
        <h4 class="table-heading">Städadress</h4>
        <h4 class="table-heading">Betyg</h4>
      </div>

      <BookingsLine
        v-for="booking in sortedBookings"
        :key="booking.id"
        :bookingData="booking"
        :isUpcoming="false"
        v-on:refresh-bookings="showBookings"
      />
    </div>
    <div class="loading-section" v-else>
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import BookingsLine from "./BookingLine";
import { compareDates } from "@/js/helpers.js";
import { previousBookings } from "@/api/bookings.api.js";

export default {
  name: "PastBookings",
  components: {
    BookingsLine,
  },
  mounted() {
    this.showBookings();
  },
  methods: {
    showBookings: function () {
      if (this.$router.currentRoute.name == "bokingar") {
        previousBookings()
          .then((response) => {
            this.pastBookings = response;
            this.bookingsLoaded = true;
          })
          .catch(() => {});
      } else {
        previousBookings(15)
          .then((response) => {
            this.pastBookings = response;
            this.bookingsLoaded = true;
          })
          .catch(() => {});
      }
    },
  },
  data() {
    return {
      bookingsLoaded: false,
      pastBookings: [],
    };
  },
  computed: {
    sortedBookings() {
      let bookings = JSON.parse(JSON.stringify(this.pastBookings));
      return bookings.sort(compareDates);
    },
  },
};
</script>

<style>
</style>
