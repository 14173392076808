import httpClient from "./httpClient";

const submitReview = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post("/submit-review", data)
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};

export { submitReview };
