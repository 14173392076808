<template>
  <div class="modal" @click.stop="closeDetails">
    <div class="modal-content">
      <div class="flex-row-no-wrap modal-header">
        <img src="@/assets/logo-inverted.png" alt="" />
      </div>
      <h4>
        Du vill avboka
        <span class="modal-highlight">{{ cleaningDate }}</span> med
        {{ bookingData.EmployeeName }}
      </h4>
      <h4>
        Datum och tid for avbokning:
        <span class="modal-highlight">{{ cancelDate }}</span>
      </h4>
      <p>
        {{ getCancelText }}
      </p>
      <h4>Bokningen kommer faktureras enligt ovan.</h4>
      <div>
        <input type="checkbox" id="agree" v-model="agree" />
        <label for="agree"
          >Jag accepterar avbokningspolicyn och avbokar städningen.
        </label>
      </div>
      <br />
      <button class="request-btn" v-if="!loading" @click="submitCancellation">
        AVBOKA
      </button>
      <div class="loading-spinner" v-else></div>
      <h4 style="margin: 1rem auto" v-if="success">avbokning skickad</h4>
    </div>
  </div>
</template>

<script>
import { cancelBooking } from "@/api/cancel.api.js";

export default {
  name: "BookingCancelModal",
  props: {
    bookingData: Object,
  },
  methods: {
    closeDetails(e) {
      if (e.target.className == "modal") {
        this.$emit("close-cancel");
      }
    },

    submitCancellation() {
      if (!this.agree) return;
      this.loading = true;
      cancelBooking(this.bookingData)
        .then(() => {
          this.$emit("close-cancel",true);
          this.loading = false;
          this.success = true;
        })
        .catch(() => (this.loading = false));
    },
  },
  data() {
    return {
      agree: false,
      loading: false,
      success: false,
    };
  },
  mounted() {},
  computed: {
    getCancelText() {
      let hours = Math.round(
        (new Date(
          this.bookingData.StartDate.split(" ")[0] +
            " " +
            this.bookingData.StartTime
        ).getTime() /
          1000 -
          Math.round(Date.now() / 1000)) /
          60 /
          60
      );
      console.log(hours);
      if (hours < 48) {
        return `Enligt vår avbokningspolicy faktureras 25% av bokningens kostnad före
        RUT-avdrag vid avbokning senare än 48 timmar inpå städtillfället.
        Anledningen till detta är att kunder enligt Skatteverkets regler inte
        får RUT-avdrag för thänster som inte utförs (avbokas).`;
      }

      return `Eftersom avbokningen görs tidigare än 48 timmar inpå städtillfället
        kommer ingenting faktureras.`;
    },
    cancelDate() {
      let currentDate = new Date(Date.now()).toISOString();
      currentDate = currentDate.split("T");
      currentDate[1] = currentDate[1].slice(0, currentDate[1].indexOf("."));
      return currentDate[0] + " " + currentDate[1];
    },
    cleaningDate() {
      return (
        this.bookingData.StartDate.split(" ")[0] +
        " " +
        this.bookingData.StartTime
      );
    },
  },
};
</script>

<style>
</style>