import Vue from "vue";
import Vuex from "vuex";
import httpClient from "../api/httpClient";

import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
        token: "",
        refreshingCall: null,
        isRefreshing: false,

    },
    mutations: {
        isRefreshing(state, isRefreshing) {
            // mutate state
            state.isRefreshing = isRefreshing;
        },
        refreshingCall(state, refreshingCall) {
            // mutate state
            state.refreshingCall = refreshingCall;
        },

        SET_TOKEN(state, token) {
            state.token = token;
            httpClient.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${token}`;


        },
        CLEAR_TOKEN(state) {
            state.token = "";
        },
    },
    actions: {},
    modules: {},
});
