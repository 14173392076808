import httpClient from "./httpClient";

const invoices = (limit = null) => {
    return new Promise((resolve, reject) => {
        //Dummy code, delete all for real thing

        httpClient.get("/invoices?limit=" + limit).then(res => {
            resolve(res.data.invoices);
        }).catch(() => {
            reject(false);

        });

    });
};

export { invoices };
