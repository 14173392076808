<template>
  <div class="modal" @click.stop="closeCancelConfirm">
    <div class="modal-content">
      <div class="flex-row-no-wrap modal-header">
        <img src="@/assets/logo-inverted.png" alt="" />
      </div>

      <p style="text-align:center;">
        {{ getCancelText }}
      </p>
      <br />
     </div>
  </div>
</template>

<script>
export default {
  name: "BookingCancelModal",
  props: {
    date: Object,
  },
  methods: {
    closeCancelConfirm(e) {
      if (e.target.className == "modal") {
        this.$emit("close-cancel-confirm");
      }
    },
  },
  mounted() {},
  computed: {
    getCancelText() {
      return `Din städning ${this.date} har nu avbokats.`;
    },
  },
};
</script>

<style>
</style>