<template>
  <!-- 
    Da row fel table bta3 el upcoming bookings
  Law 7t8yr el columns make sure enak t8yr structure el table
  Fel parent bta3oh (PastBookings.vue) w all should be good 
  -->
  <div class="table-row">
    <h4 class="mobile-heading table-heading">Veckodag</h4>
    <h4 class="table-entry">{{ getDay }}</h4>
    <h4 class="mobile-heading table-heading">Datum</h4>
    <h4 class="table-entry">{{ getStartDate }}</h4>
    <h4 class="mobile-heading table-heading">Tid</h4>
    <h4 class="table-entry">{{ getStartTime }} - {{ bookingData.EndTime }}</h4>
    <h4 class="mobile-heading table-heading">Städare</h4>
    <h4 class="table-entry">{{ bookingData.EmployeeName }}</h4>
    <h4 class="mobile-heading table-heading">Städadress</h4>
    <h4 class="table-entry">{{ bookingData.ProjectName }}</h4>
    <h4 class="mobile-heading table-heading">
      {{ isUpcoming ? "Åtgärd" : "Betyg" }}
    </h4>
    <h4 class="table-entry table-link">
      <span v-if="bookingData.rating == null" @click="openPopup">
        <a href="#">{{ isUpcoming ? "Avboka" : "Betygsätt" }}</a>
      </span>
      <span v-else>
        <StartRating
          class="modal-rating"
          v-model="bookingData.rating.rating"
          :increment="0.5"
          :star-size="20"
          :show-rating="false"
          :read-only="true"
        />
      </span>
    </h4>
    <BookingCancelModal
      v-if="cancelOpen && isUpcoming"
      v-on:close-cancel="closeCancel"
      :bookingData="bookingData"
    />
    <BookingRateModal
      v-if="rateOpen && !isUpcoming"
      v-on:close-rate="closeRate"
      v-on:refresh-bookings="refreshBookings"
      :bookingData="bookingData"
    />
    <BookingRateConfirm
      v-if="rateConfirmOpen"
      v-on:close-confirm="closeConfirmRate"
    />
    <BookingCancelConfirm
      v-if="cancelConfirmOpen"
      :date="bookingData.StartDate.split(' ')[0] + ' ' + bookingData.StartTime"
      v-on:close-cancel-confirm="closeCancelConfirm"
    />
  </div>
</template>

<script>
import StartRating from "vue-star-rating";

import BookingCancelModal from "./BookingCancelModal";
import BookingRateModal from "./BookingRateModal";
import BookingRateConfirm from "./BookingRateConfirm";
import BookingCancelConfirm from "./BookingCancelConfirm";

import { getWeekDay } from "@/js/helpers.js";

export default {
  name: "BookingLine",
  props: {
    bookingData: Object,
    isUpcoming: Boolean,
  },
  components: {
    BookingCancelModal,
    BookingRateModal,
    StartRating,
    BookingRateConfirm,
    BookingCancelConfirm,
  },
  mounted() {
    console.log(this.bookingData);
  },
  data() {
    return {
      cancelConfirmOpen: false,
      rateConfirmOpen: false,
      cancelOpen: false,
      rateOpen: false,
    };
  },
  methods: {
    openPopup(e) {
      console.log(e.target);
      if (this.isUpcoming) this.cancelOpen = true;
      else this.rateOpen = true;
    },
    closeCancel(confirm) {
      this.cancelOpen = false;
      if (confirm) {
        this.cancelConfirmOpen = true;
      }
    },
    closeConfirmRate() {
      this.rateConfirmOpen = false;
    },
    closeCancelConfirm() {
      this.cancelConfirmOpen = false;
    },
    refreshBookings(){
      this.$emit('refresh-bookings');
    },
    closeRate(confirm) {
      this.rateOpen = false;
      
      if (confirm) {
        this.rateConfirmOpen = true;
      }
    },
  },
  computed: {
    getStartTime() {
      let BookingTime = this.bookingData.StartTime.split(":");
      return BookingTime[0] + ":" + BookingTime[1];
    },
    getStartDate() {
      let BookingDate = this.bookingData.StartDate.split(" ");
      return BookingDate[0];
    },
    getDay() {
      return getWeekDay(this.bookingData.StartDate.split(" ")[0]);
    },
  },
};
</script>

<style>
.table-link {
  cursor: pointer;
}
</style>
