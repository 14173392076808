<template>
  <div id="loginView" class="sp_forms_views">
    <div class="flex-col img-login-panel">
      <video
        src="/Hembry preview.mp4"
        style="max-width: 100%; object-fit: cover"
        autoplay
        muted
        height="100%"
        loop
      ></video>
    </div>
    <div class="login-panel">
      <div class="flex-row-no-wrap logo-row">
        <img class="logo" src="../assets/logo.png" alt="" />
      </div>

      <!-- <form> -->
      <form class="auth-form" v-on:submit="requestCode">
        <h2 style="margin-bottom: 0;">Återställ lösenord</h2>
        <h4>Det kan ta några minuter för mailet att skickas.</h4>

        <label class="form-label" for="email">Email</label>
        <input
          id="email"
          type="email"
          class="login-text-input form-text-input"
          v-model="email"
          required
        />
 

      
        <div id="loginBtnRow" class="flex-row-no-wrap submit-btn-row">
          <button type="submit" id="loginBtn" class="submit-btn">
            <h4>Återställ lösenord</h4>
            <img src="../assets/icons/arrow-right.svg" alt="" />
            <!-- Circle for login animation -->
            <transition name="enlarge"
              ><div id="circle" v-if="animationFlag"></div
            ></transition>
          </button>
          <div
            v-if="loading"
            style="
              align-self: center;
              margin-left: 1rem;
              border-radius: 50%;
              height: 20px;
              width: 24px;
              border: 3px solid #e7e7e7;
              border-top: 3px solid blue;
              animation: spinAnimation linear 2s infinite;
            "
          ></div>
        </div>

        <div class="sp_reg_bottom">
        <span>or</span>
        <small><router-link to="/Login">LOGGA IN</router-link></small>
        </div>
        
      </form>

      <!-- error section -->
      <div v-if="showError" class="error-container">
        <section>{{ errorMsg }}</section>
      </div>
      <div v-if="showSuccess" class="success-container">
        <section>Lösenordsåterställning lyckades, nu kan du logga in</section>
      </div>
    </div>
  </div>
</template>

<script>
import { code_reset } from "@/api/auth.api.js";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      confirmedPassword: "",
      identityNum: "",
      animationFlag: false,
      loading: false,
      showSuccess: false,
      errorMsg: "",
      showError: false,
      code: "",
    };
  },
  methods: {
    requestCode(e) {

      e.preventDefault();

      code_reset(this.email)
        .then(() => {
          this.$notify({
            group: "foo",
            title: "Reset Code",
            text: "Tack! Vi har skickat en länk till din mailadress för att säkerställa att du är du.",
            type: "success",
            position: "bottom right",
          });
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            title: "Reset Code",
            text: "Couldn't send the code to the email!",
            type: "error",
            position: "bottom right",
          });
        });
    },
  },
};
</script>


<style>

.sp_forms_views form>h2 {
text-transform: uppercase;
}

</style>


<style scoped>
/* Change order of stacked columns on mobile */

.auth-form {
  width: 300px;
}

@media (max-width: 767px) {
  .login-panel {
    order: 1;
  }

  .img-login-panel {
    height: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
  }
  .img-login-panel::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .sp_forms_views .form-label,
  .sp_reg_bottom>small a {
     color: #666;
  }
}

/* Override grid ratio for register page */
@media (min-width: 768px) {
  #loginView {
    grid-template-columns: 2fr 3fr;
  }
}
</style>
