<template>
  <div class="flex-row-no-wrap logo-row">
    <router-link :to="{ name: 'Home' }"
      ><img class="logo" src="../../assets/logo.png" alt=""
    /></router-link>
    <a
      v-if="!onDashboard"
      class="nav-item"
      @click="$router.push({ name: 'Home' })"
      >Bakåt</a
    >

    <div style="margin: auto"></div>
    <router-link to="bokingar" class="nav-item">Bokningar</router-link>
    <router-link to="fakturor" class="nav-item">Fakturor</router-link>
    <div
      class="nav-item dropdown"
      v-on:click="openDropdown"
      v-click-outside="closeDropdown"
    >
      {{ name }}
      <i
        class="fas"
        :class="{ 'fa-angle-down': dropdown == false, 'fa-angle-up': dropdown }"
      ></i>

      <div class="dropdown-items" v-if="dropdown == true">
        <router-link to="settings" class="nav-item"
          >Inställningar</router-link
        >
        <a href="#" class="nav-item" v-on:click="logout">Logga ut</a>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient.js";
import ClickOutside from "vue-click-outside";

export default {
  name: "NavBar",
  data() {
    return {
      name: "",
      dropdown: false,
    };
  },
  computed: {
    onDashboard() {
      return this.$router.currentRoute.name == "Home";
    },
  },
  mounted() {
    this.user();
  },
  methods: {
    openDropdown: function () {
      this.dropdown = !this.dropdown;
    },
    closeDropdown: function () {
      this.dropdown = false;
    },
    logout: function () {
      this.$store.commit("CLEAR_TOKEN");
      this.$router.push({ name: "Login" });
    },
    user: function () {
      httpClient.get("/user").then((res) => {
        let name = res.data.name.split(" ");

        this.name = name[0][0] + " " + name[1][0];
      });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
.nav-item {
  margin: 0;
  margin-left: 1rem;
  align-self: center;
  justify-self: end;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-weight: normal;
  /* color: #9d9d9d; */
  color: rgba(13, 29, 82, 1);
}
.dropdown {
  position: relative;
}
.dropdown i {
  margin-left: 5px;
}
.dropdown .dropdown-items {
  position: absolute;
  left: -130px;
  width: 150px;
  top: 40px;
  background: white;
  padding: 10px;
  border-radius: 5px;
}
.dropdown .dropdown-items a {
  display: block;
}
.nav-item:hover {
  border-bottom: 1px solid #6c9cd6;
}
</style>