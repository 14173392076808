<template>
  <!-- 
  El section da bta3 kroot el bookings el upcoming
  Byzhr 3ala el homepage wel page bta3t el bookings 
  -->
  <div>
    <div class="flex-row-no-wrap section-title-row">
      <h4 class="section-title">Kommande bokningar</h4>
      <router-link
        class="show-all"
        to="bokingar"
        v-if="this.$router.currentRoute.name != 'bokingar'"
        >Visa alla</router-link
      >
    </div>

    <div class="table-container" v-if="bookingsLoaded">
      <div class="table-row">
        <h4 class="table-heading">Veckodag</h4>
        <h4 class="table-heading">Datum</h4>
        <h4 class="table-heading">Tid</h4>
        <h4 class="table-heading">Städare</h4>
        <h4 class="table-heading">Städadress</h4>
        <h4 class="table-heading">Åtgärd</h4>
      </div>

      <BookingsLine
        v-for="booking in upcomingBookings"
        :key="booking.id"
        :bookingData="booking"
        :isUpcoming="true"
        v-on:refresh-bookings="showBookings"
      />
    </div>

    <div class="loading-section" v-else>
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import BookingsLine from "@/components/bookings-components/BookingLine";
import { compareDates } from "@/js/helpers.js";
import { upcomingBookings } from "@/api/bookings.api.js";

export default {
   components: {
    BookingsLine,
  },
  mounted() {
    console.log("??");
    console.log("??????");
    this.showBookings();
  },
  methods: {
    showBookings: function () {
       if (this.$router.currentRoute.name == "Bookings") {
        upcomingBookings()
          .then((response) => {
            this.upcomingBookings = response;
            this.bookingsLoaded = true;
          })
          .catch(() => {});
      } else {
         
        upcomingBookings(3)
          .then((response) => {
            console.log(response);
            this.upcomingBookings = response;
            this.bookingsLoaded = true;
          })
          .catch(() => {});
      }
    },
  },
  data() {
    return {
      bookingsLoaded: false,
      upcomingBookings: [],
    };
  },
  computed: {
    sortedBookings() {
      let bookings = JSON.parse(JSON.stringify(this.upcomingBookings));
      return bookings.sort(compareDates);
    },
  },
};
</script>

<style>
.section-title-row {
  margin-bottom: 1rem;
  margin-left: 10px;
}

.section-title {
  margin: 0px;
  color: #777c82;
}

.show-all {
  margin: 0px;
  margin-left: auto;
  align-self: center;
  cursor: pointer;
  font-size: 0.67em;
  text-decoration: none;
  color: #2c3e50;
  font-weight: bold;
}

#bookingsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
}

.loading-section {
  margin: 1rem 0;
}

.loading-spinner {
  height: 2rem;
  width: 2rem;
  border: 6px solid #6c9cd6;
  border-color: #6c9cd6 transparent #6c9cd6 transparent;
  border-radius: 50%;
  margin: auto;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>