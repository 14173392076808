<template>
  <div class="modal" @click.stop="closeConfirm">
    <div class="modal-content">
      <div class="flex-row-no-wrap modal-header">
        <img src="@/assets/logo-inverted.png" alt="" />
      </div>

      <p style="text-align:center;">Tack för din feedback! Den gör att vi utvecklas.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeConfirm(e) {
      if (e.target.className == "modal") {
        this.$emit("close-confirm");
      }
    },
  },
};
</script>