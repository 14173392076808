import httpClient from "./httpClient";
// import store from "../store";

//Printing to avoid declared but not used error 0_0



const upcomingBookings = (limit = null) => {
    // Iso formatted date for api and token from state
    // Now and next year for upcoming bookings
    // const now = new Date().toISOString();
    // const nextYear = new Date(
    //     new Date().setFullYear(new Date().getFullYear() + 1)
    // ).toISOString();
    // const token = store.state.token;

    // console.log(
    //     "Now : " + now + "\nNext year : " + nextYear + "\n Token :" + token
    // );

    return new Promise((resolve, reject) => {
        //Api call here, on success use resolve(JSON RESULT FROM API)
        // Dummy code

        let url = "/upcoming-bookings";

        if (limit != null) {
            url = "/upcoming-bookings?limit=" + limit;
        }
        httpClient.get(url).then(res => {
            console.log(res);
            resolve(res.data.customer_bookings);
        }).catch(() => {
            reject(false);

        });

    });
};

const previousBookings = (limit = null) => {
    // Iso formatted date for api and token from state



    return new Promise((resolve, reject) => {
        //Api call here, on success use resolve(JSON RESULT FROM API)
        // Dummy code

        let url = "/previous-bookings";

        if (limit != null) {
            url = "/previous-bookings?limit=" + limit;
        }

        httpClient.get(url).then(res => {
            resolve(res.data.customer_bookings);
            console.log(res.data.customer_bookings);
        }).catch(() => {
            reject(false);
        });

    });
};

export { upcomingBookings, previousBookings };
