<template>
  <div class="view">
    <NavBar />

    <UpcomingBookings />

    <PastBookings />
  </div>
</template>

<script>
import NavBar from "../components/dashboard-components/NavBar";
import UpcomingBookings from "../components/bookings-components/UpcomingBookings.vue";
import PastBookings from "../components/bookings-components/PastBookings.vue";

export default {
  name: "Bookings",
  components: {
    NavBar,
    UpcomingBookings,
    PastBookings,
  },
};
</script>

<style>
</style>